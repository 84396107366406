import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"

const HelpCenter = ({
  data: {
    allNodeTemplatePages: { edges },
  },
}) => {
  const helpCentre = edges[0]
  const metaTags =
    helpCentre.node?.metatag_normalized.length > 0
      ? helpCentre?.node?.metatag_normalized
      : []
      const schema = helpCentre?.node?.relationships?.field_seo_schema ? helpCentre?.node?.relationships?.field_seo_schema : []
  const canonicalUrl = helpCentre?.node?.metatags_on_page || {}
  const data = helpCentre?.node?.relationships?.field_component_type
  const bannerhelpcentre = data.filter((el)=>{
      if(el.name === "banner_help_centre"){
          return el;
      }
  })
  const needhelp =data.filter((el)=>{
      if(el.name === "section_need_help"){
          return el;
      }
  })
  const sectionfaqs =data.filter((el)=>{
    if(el.name === "section_faqs"){
        return el;
    }
})

const accordian = sectionfaqs[0]?.relationships?.components
  const banner = bannerhelpcentre[0]?.relationships?.components[0]
  const bannerImage = banner?.relationships?.bannerImage?.uri?.url
  const bannerTitle = banner?.title || ""

  const bannerImageAlt =
    bannerhelpcentre[0]?.bannerImage?.alt.length > 0
      ? bannerhelpcentre[0]?.bannerImage?.alt
      : "Hinduja Hospital "

  const needhelpData = needhelp[0]?.relationships?.components[0]
  const needhelpImage = needhelpData?.relationships?.image?.uri?.url;
  const needhelpAlt = needhelpData?.image?.alt;
  const needhelpTitle = needhelpData?.title;
  const needhelpDescription = needhelpData?.description?.processed

  return (
    <>
      <Layout>
        <Meta
          files={{
            js: [],
            css: ["/assets/css/blog.css", "/assets/css/accessibility.css"],
          }}
          tags={{metaTags,canonicalUrl}}
        />
        <Schema schema={schema} />
        <main className="innerpage">
            {bannerhelpcentre?.length && !bannerImage === null > 0 &&(
          <section className="inner_common_banner">
            <img
              src={bannerImage}
              alt={bannerImageAlt}
              className="banner_img lazyload"
            />
            <div className="banner_content text_left text_dark">
              <div className="container">
                <div className="bannerDescription">
                  <h1>
                    {bannerTitle.length > 0
                      ? bannerTitle
                      : "Your Clinic Visit - Help-Centre"}
                  </h1>
                </div>
              </div>
            </div>
          </section>
          )}
          <section className="section-bg section-py">
            <div className="container">
              <div className="help_center_container">
                <div className="arrowhead">
                  <Link to="/user/dashboard" className="back_arrow">
                    <img
                      src="/assets/images/icons/left-arrow-event.svg"
                      alt=""
                    />
                  </Link>
                </div>
                       {needhelp?.length > 0 &&(
                      <div className="help_center_head">
                        <span className="img_wrap">
                          <img
                            src={needhelpImage}
                            alt={needhelpAlt}
                          />
                        </span>
                        <h2 className="section-heading">{needhelpTitle}</h2>
                        <p
                          className="subhead"
                          dangerouslySetInnerHTML={{
                            __html: needhelpDescription,
                          }}
                        />
                      </div>
                   )}
                
                <div className="white_rounded_box p-4">
                  <div className="accordion_container">
                    <h2>FAQs</h2>
                    <div
                      id="accordion"
                      role="tablist"
                      className="common_accordion specialty_accordion"
                    >
                      { accordian.map((item, index) => {
                          return (
                            <div className="card" key={index}>
                              <div
                                className="card-header"
                                role="tab"
                                id={`heading_inpatient0${index + 1}`}
                              >
                                <h5 className="mb-0">
                                  <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    href={`#outpatient_0${index + 1}`}
                                    aria-expanded="false"
                                    id={index + 1}
                                  >
                                    {item?.field_title}
                                  </a>
                                </h5>
                              </div>
                              <div
                                id={`outpatient_0${index + 1}`}
                                className="collapse"
                                role="tabpanel"
                                aria-labelledby="heading_inpatient01"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item?.text?.processed,
                                      }}
                                    />
                                </div>
                              </div>
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query helpCentre {
    allNodeTemplatePages(
      filter: { path: { alias: { regex: "/help-centre$/" } } }
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          relationships{
            field_seo_schema{
                field_seo_title
                field_seo_schema_text_area
            }
          }
          metatag_normalized {
            attributes {
              content
              name
              property
            }
          }
          metatags_on_page: field_meta_tags{
            canonical_url
          }
          relationships {
            field_component_type {
              __typename
              ...ParagraphSectionhc
            }
          }
        }
      }
    }
  }

  fragment ParagraphSectionhc on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphBannerhc
        ...ParagraphTitleAndDescriptionhc
        ...ParagraphImageWithTitleAndDescription
      }
    }
  }

  fragment ParagraphTitleAndDescriptionhc on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
    field_text {
      processed
    }
  }

  fragment ParagraphImageWithTitleAndDescription on paragraph__image_with_title_and_description {
    id
    title: field_title
    description: field_content {
      processed
    }
    image: field_image {
      alt
    }
    alignment: field_alignment
    relationships {
      image: field_image {
        id
        uri {
          value
          url
        }
      }
    }
  }

  fragment ParagraphBannerhc on paragraph__banner {
    id
    title: field_title
    subtitle: field_subtitle
    bannerImage: field_banner_image {
      alt
    }
    bannerLink: field_banner_link {
      uri
      title
    }
    relationships {
      bannerImage: field_banner_image {
        id
        uri {
          value
          url
        }
      }
    }
  }
`
export default HelpCenter
